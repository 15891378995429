.navbar-custom {
    color: var(text-white) !important;
}

.pairup-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, #FFF7B1 0%, rgba(255, 255, 255, 0) 71.88%), #FFFFFF; */
    /* box-shadow: 0px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
    border: var(--spacing-0) !important;
    border-radius: var(--spacing-20) !important;
    padding: var(--spacing-12);
}

.pairup-photo {
    width: var(--spacing-48);
    height: var(--spacing-48);
    object-fit: contain;
    border: 1px solid var(--color-gray-200);
    border-radius: 50%;
}

.pairup-icon {
    color: var(--color-turqoise) !important;
    border-color: var(--color-turqoise) !important;
    background-color: var(--color-turqoise) !important;
}

.text-normal {
    color: var(--color-black) !important;
    text-decoration: none !important;
}

.text-decoration-none,
.no-decoration {
    text-decoration: none !important;
}

.max-w-70 {
    max-width: 70vw !important;
    padding: var(--spacing-24);
}

/* Chat Messages */
.chat-messages {
    overflow-y: scroll;
}

.chat-messages .message {
    border-radius: var(--spacing-12);
    font-size: var(--spacing-12);
    padding: var(--spacing-8);
    max-width: 75vw;
}

.chat-messages .text-left {
    background-color: var(--color-blue-400) !important;
    color: var(--color-white) !important;
    border-top-left-radius: 0%;
    align-self: flex-start !important;
}

.chat-messages .text-right {
    background-color: var(--color-gray-600) !important;
    color: var(--color-white) !important;
    border-bottom-right-radius: 0%;
    align-self: flex-end !important;
}

.chat-messages .new-message {
    background-color: var(--color-white) !important;
    z-index: 1;
}

.pal-photo {
    width: var(--spacing-32);
    height: var(--spacing-32);
    object-fit: contain;
    border: 1px solid var(--color-gray-200);
    border-radius: 50%;
}

/* Home Page CSS */

.welcome-section {
  background-image: url("https://t4.ftcdn.net/jpg/05/55/89/53/360_F_555895347_Ef8KZBnAucLAD0BL7lOdd9EbUroSl52Y.jpg");
  background-position: center;
  background-attachment: fixed;
  width: 100vw;
  min-height: 50vh;
  color: var(--color-white);
}

.welcome-section .welcome-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 50vh;
  background-color: rgba(0, 0, 0, 0.5);
}


.welcome-section .welcome-content {
  position: relative;
  z-index: 1;
}

.why-use-pairuppal {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.section-title {
  font-size: 2rem;
  color: #333;
}

.feature {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #007bff;
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.feature-description {
  font-size: 1rem;
  color: #666;
}
.footer {
  background-color: #333;
  color: #fff;
  padding: 50px 0;
}

.footer h4 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 20px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #f39c12;
}

.social-links {
  list-style-type: none;
  padding: 0;
}

.social-links li {
  display: inline-block;
  margin-right: 10px;
}

.social-links a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #f39c12;
}

@media (max-width: 768px) {

  .welcome-section,
  .welcome-section .welcome-overlay {
    min-height: 70vh;
  }
}